<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
            :disabled="disabled">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"></span>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            disabled: {
                type: Boolean,
                default: false
            },
			rates: {
				type: Array,
				default() {
					return ['TR','YU']
				}
			}
		},
		data() {
			return {
				selected: null,
				options: []
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.options.filter(c => c.value == newValue)
			}
		},
		created() {
			this.getOptions()
			this.selected = this.options.filter(c => c.value == this.value)
		},
		methods: {
			getOptions() {
				this.options = [];
				this.rates.forEach((value) => {
					this.options.push({value: value, text: value});
				})
			},
			handleInput(event) {
				if (event && event.value) {
					this.$emit('input', event.value);
				}
				else {
					this.$emit('input', 0);
				}
			}
		}
	}
</script>
